import React, { useState, useRef, useEffect } from "react";
import { STREAM_CHANNEL_TYPE, DASHBOARD_WIDGET_TOOLBAR_COLLAPSE_TIMEOUT } from "../../constants";
import _ from "lodash";

function LayoutWidgetToolbar({ widget, dashboardProps, options = {} }) {
  const [isExpanded, setIsExpanded] = useState(false);
  const toolbarRef = useRef(null);
  const collapseTimeout = useRef(null);
  const extendCollapseTimeout = useRef(null);

  const { fullscreen } = options;

  useEffect(() => {
    document.addEventListener("mousedown", handleCollapser);

    extendCollapseTimeout.current = _.debounce(extendCollapseTimeoutFunc, 400, { maxWait: 400 });

    return () => {
      document.removeEventListener("mousedown", handleCollapser);
    };
  }, []);

  useEffect(() => {
    if (isExpanded) {
      clearTimeout(collapseTimeout.current);
      collapseTimeout.current = setTimeout(() => {
        setIsExpanded(false);
        collapseTimeout.current = null;
      }, DASHBOARD_WIDGET_TOOLBAR_COLLAPSE_TIMEOUT);
    } else {
      clearTimeout(collapseTimeout.current);
    }
  }, [isExpanded]);

  function handleCollapser(e) {
    if (!collapseTimeout.current) return;

    if (toolbarRef.current && !toolbarRef.current.contains(e.target)) {
      setIsExpanded(false);
    }
  }

  function extendCollapseTimeoutFunc() {
    clearTimeout(collapseTimeout.current);
    collapseTimeout.current = setTimeout(() => {
      setIsExpanded(false);
      collapseTimeout.current = null;
    }, DASHBOARD_WIDGET_TOOLBAR_COLLAPSE_TIMEOUT);
  }

  const { maximiseView, openLayoutWidgetPopout, updateWindowPlacementPermissionRequested, layoutCurrentDevice } =
    dashboardProps;

  let { stream } = dashboardProps.config;
  stream = (stream || []).filter((item) => item.type && item.type !== STREAM_CHANNEL_TYPE.NONE);

  return (
    <div
      className="layout-widget-toolbar-outer"
      ref={toolbarRef}
      onMouseMove={(e) => {
        extendCollapseTimeout.current();
      }}
    >
      <div className={"layout-widget-toolbar-hider" + (isExpanded ? " expand-toolbar" : " collapse-toolbar")}>
        <div className="layout-widget-toolbar">
          <span>
            <i
              className="fa fa-external-link"
              onClick={async () => {
                try {
                  const { state } = await navigator.permissions.query({
                    name: "window-management",
                  });
                  const granted = state === "granted";
                  if (!granted) {
                    await window.getScreenDetails();
                  }
                } catch (e) {
                  console.log(e);
                  // Do nothing
                }
                maximiseView(null);
                openLayoutWidgetPopout(widget);
                updateWindowPlacementPermissionRequested(true);
              }}
            />
          </span>
          <i className="layout-dnd-handler mdi mdi-drag-horizontal"></i>
          <span>
            <i
              className={fullscreen ? "mdi mdi-close" : "fa fa-expand"}
              onClick={() => {
                maximiseView(fullscreen ? null : widget);
              }}
            />
          </span>
        </div>
      </div>

      <div
        className={`mdi mdi-chevron-down expand-button`}
        style={isExpanded ? { marginTop: "3px", opacity: 0, fontSize: "1px" } : {}}
        onClick={() => {
          setIsExpanded(!isExpanded);
        }}
      ></div>
    </div>
  );
}

export default LayoutWidgetToolbar;
