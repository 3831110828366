import { createActions, createReducer } from "reduxsauce";
import {
  DEFAULT_DASHBOARD_LAYOUT,
  DEFAULT_DASHBOARD_LAYOUT_CONFIG,
  DEFAULT_SEARCH_DROPDOWN,
  DEFAULT_SYMBOL_POPUP,
  DEFAULT_DASHBOARD_LAYOUT_MULTI_STREAM,
  DEFAULT_DASHBOARD_MENU,
  DASHBOARD_LAYOUT_DEVICE_SIZES,
} from "../constants";
import { complementLayout } from "../util";
import _ from "lodash";

const defaultState = {
  showSpinner: false,

  contextMenuSymbol: null,

  maximisedView: "",
  layout: DEFAULT_DASHBOARD_LAYOUT_CONFIG,
  layoutRestored: false,
  layoutForceUpdate: 0,
  isPagePopout: false,
  isPopoutBlocked: false,
  isPageFlow: false,

  windowPlacementPermissionRequested: false,

  alerts: [],
  alertDetailItem: null,
  popularSymbols: [],
  symbolPopup: DEFAULT_SYMBOL_POPUP,
  searchDropdown: DEFAULT_SEARCH_DROPDOWN,
};

export const { Types, Creators } = createActions({
  maximiseView: ["view"],
  updateContextMenuSymbol: ["symbol"],
  showSpinner: [],
  hideSpinner: [],
  setPopularSymbols: ["value"],
  setAlerts: ["value"],
  addAlerts: ["value"],
  setAlertDetailItem: ["value"],
  updateSymbolPopup: ["value"],
  updateSearchDropdown: ["value"],
  updateSearchDropdownClicked: ["value", "extra"],
  resetLayout: ["value"],
  setLayoutConfig: ["value"],
  setLayoutActive: ["value"],
  updateActiveLayoutDeviceLayout: ["value"],
  toggleLayoutLock: ["value"],
  toggleLayoutWidget: ["value"],
  updateLayoutLabel: ["value"],
  resetLayoutWidget: [],
  openLayoutWidgetPopout: ["value"],
  closeLayoutWidgetPopout: ["value"],
  updateLayoutWidgetScreenInfo: ["value"],
  updateLayoutCurrentDevice: ["value"],
  triggerLayoutForceUpdate: [],
  setIsPagePopout: ["value"],
  setIsPopoutBlocked: ["value"],
  setIsPageFlow: ["value"],
  updateWindowPlacementPermissionRequested: ["value"],
  chooseSymbol: ["symbol"],
});

const showSpinner = (state) => ({
  ...state,
  showSpinner: true,
});

const hideSpinner = (state) => ({
  ...state,
  showSpinner: false,
});

const maximiseView = (state, { view }) => ({
  ...state,
  maximisedView: view,
});

const updateContextMenuSymbol = (state, { symbol }) => ({
  ...state,
  contextMenuSymbol: symbol,
});

const setPopularSymbols = (state, { value }) => ({
  ...state,
  popularSymbols: value || [],
});

const setAlerts = (state, { value }) => ({
  ...state,
  alerts: value || [],
});

const addAlerts = (state, { value }) => ({
  ...state,
  alerts: [...value, ...state.alerts],
});

const setAlertDetailItem = (state, { value }) => ({
  ...state,
  alertDetailItem: value,
});

const updateSymbolPopup = (state, { value }) => {
  return {
    ...state,
    symbolPopup: {
      ...(state.symbolPopup || {}),
      ...value,
    },
  };
};

const updateSearchDropdown = (state, { value }) => {
  return {
    ...state,
    searchDropdown: {
      ...(state.searchDropdown || {}),
      ...value,
      clicked: {
        symbol: "",
        source: "",
        extra: null,
      },
    },
  };
};

const updateSearchDropdownClicked = (state, { value, extra }) => {
  const clickedSource = value ? state?.searchDropdown?.source : "";
  return {
    ...state,
    searchDropdown: {
      ...state.searchDropdown,
      clicked: {
        symbol: value,
        source: clickedSource,
        extra,
      },
    },
  };
};

const resetLayout = (state, { value }) => {
  const device = state.layoutCurrentDevice;

  return {
    ...state,
    maximisedView: "",
    layout: [
      ...(state.layout || DEFAULT_DASHBOARD_LAYOUT_CONFIG).map((item) =>
        item.key === value
          ? _.set(
              _.cloneDeep(item),
              `devices.${device}`,
              DEFAULT_DASHBOARD_LAYOUT_CONFIG.find((defaultItem) => defaultItem.key === item.key).devices[device]
            )
          : item
      ),
    ],
  };
};

const setLayoutConfig = (state, { value }) => {
  const { layout: layoutConfig } = value;
  // keep active/inactive information
  if (Array.isArray(state.layout)) {
    const activeInfo = {};
    for (const item of state.layout) {
      activeInfo[item.key] = item.active;
    }
    Array.isArray(layoutConfig) && layoutConfig.map((item) => (item.active = activeInfo[item.key]));
  }

  let newLayoutConfig;
  if (!layoutConfig || !Array.isArray(layoutConfig) || !layoutConfig.length) {
    newLayoutConfig = DEFAULT_DASHBOARD_LAYOUT_CONFIG;
  } else {
    newLayoutConfig = complementLayout(layoutConfig);
  }

  return {
    ...state,
    layout: newLayoutConfig,
    layoutRestored: true,
  };
};

const setLayoutActive = (state, { value }) => {
  return {
    ...state,
    layout: [
      ...(state.layout || DEFAULT_DASHBOARD_LAYOUT_CONFIG).map((item) => ({
        ...item,
        active: item.key === value,
      })),
    ],
  };
};

const updateActiveLayoutDeviceLayout = (state, { value }) => {
  const device = state.layoutCurrentDevice;

  return {
    ...state,
    layout: [
      ...(state.layout || DEFAULT_DASHBOARD_LAYOUT_CONFIG).map((item) =>
        item.active ? _.set(_.cloneDeep(item), `devices.${device}.layout`, value) : item
      ),
    ],
  };
};

const toggleLayoutLock = (state, { value }) => {
  return {
    ...state,
    layout: [
      ...(state.layout || DEFAULT_DASHBOARD_LAYOUT_CONFIG).map((item) => ({
        ...item,
        locked: item.key === value ? !item.locked : item.locked,
      })),
    ],
  };
};

const updateLayoutLabel = (state, { value }) => {
  const { key, label } = value;
  return {
    ...state,
    layout: [
      ...(state.layout || DEFAULT_DASHBOARD_LAYOUT_CONFIG).map((item) =>
        item.key === key ? { ...item, label } : item
      ),
    ],
  };
};

const toggleLayoutWidget = (state, { value }) => {
  const device = state.layoutCurrentDevice;
  return {
    ...state,
    layout: [
      ...(state.layout || DEFAULT_DASHBOARD_LAYOUT_CONFIG).map((item) =>
        item.active
          ? _.set(_.cloneDeep(item), `devices.${device}.menu.${value}`, !item.devices[device].menu[value])
          : item
      ),
    ],
  };
};

const resetLayoutWidget = (state) => {
  const device = state.layoutCurrentDevice;
  return {
    ...state,
    layout: [
      ...(state.layout || DEFAULT_DASHBOARD_LAYOUT_CONFIG).map((item) =>
        item.active ? _.set(_.cloneDeep(item), `devices.${device}.menu`, DEFAULT_DASHBOARD_MENU) : item
      ),
    ],
  };
};

const openLayoutWidgetPopout = (state, { value }) => {
  const device = state.layoutCurrentDevice;

  return {
    ...state,
    layout: [
      ...(state.layout || DEFAULT_DASHBOARD_LAYOUT_CONFIG).map((item) => {
        if (item.active) {
          const popout = [...(item.devices[device].popout || [])];
          if (!popout.find((item) => item.widget === value)) {
            popout.push({
              widget: value,
              screen: null,
            });
          }

          return _.set(_.cloneDeep(item), `devices.${device}.popout`, popout);
        } else {
          return item;
        }
      }),
    ],
  };
};

const closeLayoutWidgetPopout = (state, { value }) => {
  const device = state.layoutCurrentDevice;

  return {
    ...state,
    layout: [
      ...(state.layout || DEFAULT_DASHBOARD_LAYOUT_CONFIG).map((item) =>
        item.active
          ? _.set(
              _.cloneDeep(item),
              `devices.${device}.popout`,
              (item.devices[device].popout || []).filter((item) => item.widget !== value)
            )
          : item
      ),
    ],
  };
};

const updateLayoutWidgetScreenInfo = (state, { value }) => {
  const { widget, screen } = value;
  const device = state.layoutCurrentDevice;

  return {
    ...state,
    layout: [
      ...(state.layout || DEFAULT_DASHBOARD_LAYOUT_CONFIG).map((item) => {
        if (item.active) {
          return _.set(
            _.cloneDeep(item),
            `devices.${device}.popout`,
            [...(item.devices[device].popout || [])].map((popoutItem) =>
              popoutItem.widget === widget ? { ...popoutItem, screen } : popoutItem
            )
          );
        } else {
          return item;
        }
      }),
    ],
  };
};

const updateLayoutCurrentDevice = (state, { value }) => {
  return {
    ...state,
    layoutCurrentDevice: value,
  };
};

const triggerLayoutForceUpdate = (state) => {
  return {
    ...state,
    layoutForceUpdate: (state.layoutForceUpdate || 0) + 1,
  };
};

const setIsPagePopout = (state, { value }) => {
  return {
    ...state,
    isPagePopout: value,
  };
};

const setIsPageFlow = (state, { value }) => {
  return {
    ...state,
    isPageFlow: value,
  };
};

const setIsPopoutBlocked = (state, { value }) => {
  return {
    ...state,
    isPopoutBlocked: value,
  };
};

const updateWindowPlacementPermissionRequested = (state, { value }) => {
  return {
    ...state,
    windowPlacementPermissionRequested: !!value,
  };
};

const chooseSymbol = (state, { symbol }) => {
  return { ...state, chosenSymbol: symbol };
};

export const DashboardTypes = Types;

export const dashboardReducer = createReducer(defaultState, {
  [Types.MAXIMISE_VIEW]: maximiseView,
  [Types.UPDATE_CONTEXT_MENU_SYMBOL]: updateContextMenuSymbol,
  [Types.SHOW_SPINNER]: showSpinner,
  [Types.HIDE_SPINNER]: hideSpinner,
  [Types.SET_POPULAR_SYMBOLS]: setPopularSymbols,
  [Types.SET_ALERTS]: setAlerts,
  [Types.ADD_ALERTS]: addAlerts,
  [Types.SET_ALERT_DETAIL_ITEM]: setAlertDetailItem,
  [Types.UPDATE_SYMBOL_POPUP]: updateSymbolPopup,
  [Types.UPDATE_SEARCH_DROPDOWN]: updateSearchDropdown,
  [Types.UPDATE_SEARCH_DROPDOWN_CLICKED]: updateSearchDropdownClicked,
  [Types.RESET_LAYOUT]: resetLayout,
  [Types.SET_LAYOUT_CONFIG]: setLayoutConfig,
  [Types.SET_LAYOUT_ACTIVE]: setLayoutActive,
  [Types.UPDATE_ACTIVE_LAYOUT_DEVICE_LAYOUT]: updateActiveLayoutDeviceLayout,
  [Types.TOGGLE_LAYOUT_LOCK]: toggleLayoutLock,
  [Types.TOGGLE_LAYOUT_WIDGET]: toggleLayoutWidget,
  [Types.UPDATE_LAYOUT_LABEL]: updateLayoutLabel,
  [Types.RESET_LAYOUT_WIDGET]: resetLayoutWidget,
  [Types.OPEN_LAYOUT_WIDGET_POPOUT]: openLayoutWidgetPopout,
  [Types.CLOSE_LAYOUT_WIDGET_POPOUT]: closeLayoutWidgetPopout,
  [Types.UPDATE_LAYOUT_WIDGET_SCREEN_INFO]: updateLayoutWidgetScreenInfo,
  [Types.UPDATE_LAYOUT_CURRENT_DEVICE]: updateLayoutCurrentDevice,
  [Types.TRIGGER_LAYOUT_FORCE_UPDATE]: triggerLayoutForceUpdate,
  [Types.SET_IS_PAGE_POPOUT]: setIsPagePopout,
  [Types.SET_IS_POPOUT_BLOCKED]: setIsPopoutBlocked,
  [Types.SET_IS_PAGE_FLOW]: setIsPageFlow,
  [Types.UPDATE_WINDOW_PLACEMENT_PERMISSION_REQUESTED]: updateWindowPlacementPermissionRequested,
  [Types.CHOOSE_SYMBOL]: chooseSymbol,
});

export default Creators;

/**
 * Utility functions
 */
export const getActiveLayoutConfig = (layoutConfig, setLayoutActive) => {
  let res;
  const activeLayout = (layoutConfig || DEFAULT_DASHBOARD_LAYOUT_CONFIG).filter((item) => item && item.active);

  if (!activeLayout.length) {
    res = DEFAULT_DASHBOARD_LAYOUT_CONFIG[0];
    setLayoutActive && setLayoutActive(res.key);
  } else if (activeLayout.length > 1) {
    res = activeLayout[0];
    setLayoutActive && setLayoutActive(res.key);
  } else {
    res = activeLayout[0];
  }

  Object.keys(DASHBOARD_LAYOUT_DEVICE_SIZES).forEach((device) => {
    if (!_.get(res, `devices.${device}.layout`)) {
      _.set(res, `devices.${device}.layout`, DEFAULT_DASHBOARD_LAYOUT[device]);
    }
    if (!_.get(res, `devices.${device}.menu`)) {
      _.set(res, `devices.${device}.menu`, DEFAULT_DASHBOARD_MENU);
    }
  });

  return res;
};

export const hasMultipleStreamWidget = (layout, setLayoutActive) => {
  const { menu } = getActiveLayoutConfig(layout, setLayoutActive);
  return menu?.stream1 && menu?.stream2;
};

export const getDefaultLayoutConfig = (layout, setLayoutActive) => {
  const layoutConfig = !hasMultipleStreamWidget(layout, setLayoutActive)
    ? DEFAULT_DASHBOARD_LAYOUT
    : DEFAULT_DASHBOARD_LAYOUT_MULTI_STREAM;
  return layoutConfig;
};

export const isWidgetVisible = (widget, deviceSpecificConfig) => {
  const { menu, popout } = deviceSpecificConfig;
  const menuKey = widget;
  return menu[menuKey] && !(popout || []).find((item) => item.widget === widget);
};

export const isWidgetPopout = (widget, layout) => {
  const { menu, popout } = layout;
  const menuKey = widget;
  return menu[menuKey] && (popout || []).find((item) => item.widget === widget);
};

export function getLayoutCurrentDevice() {
  const device = Object.keys(DASHBOARD_LAYOUT_DEVICE_SIZES).find(
    (device) => matchMedia(DASHBOARD_LAYOUT_DEVICE_SIZES[device]).matches
  );
  return device;
}

export function getDeviceActiveLayoutConfig(layout) {
  if (!layout) return;
  const device = getLayoutCurrentDevice();
  const layoutConfig = getActiveLayoutConfig(layout, () => {});

  return layoutConfig.devices[device];
}

export const getDefaultLayoutForDevice = (multipleStream) => {
  const device = getLayoutCurrentDevice();

  const layout = (multipleStream ? DEFAULT_DASHBOARD_LAYOUT_MULTI_STREAM : DEFAULT_DASHBOARD_LAYOUT)[device];

  return { device, layout };
};
