import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Scrollbars } from "react-custom-scrollbars-2";

import API from "../api";

import Symbol from "../shared/Symbol";
import { Types as DashboardActionTypes } from "../dashboard/dashboardReducer";
import { useDataSource } from "../../contexts/datasource/provider";

const Popular = function (props) {
  const dispatch = useDispatch();
  const { maximisedView, popularSymbols } = useSelector((state) => state.dashboard);

  const datasource = useDataSource();

  const quoteRefs = useRef({});

  useEffect(() => {
    const timerId = setInterval(() => {
      getPopularData();
    }, 3 * 60 * 1000); // Update Every 3 minutes

    getPopularData();

    return () => clearInterval(timerId);
  }, []);

  useEffect(() => {
    if (datasource.primary) {
      datasource.primary.on("compressedUpdate", onCompressedUpdate);
    }

    return () => {
      datasource.primary?.off("compressedUpdate", onCompressedUpdate);
    };
  }, [datasource.primary]);

  const onCompressedUpdate = (event) => {
    const data = event.detail;
    let msg = data[0];
    let highs = msg[1];
    let lows = msg[2];

    // Popular blinking
    highs.concat(lows).map((item) => {
      const symbol = item[0];
      if (!quoteRefs.current[symbol]) {
        return;
      }
      const quoteRef = quoteRefs.current[symbol];
      if (quoteRef.ref) {
        const elemSymbol = quoteRef.ref.querySelector(".quote-symbol");
        elemSymbol.classList.add("symbol-blink");
      }

      if (!quoteRef.timerId) {
        let timeout = 5 * 1000;
        quoteRef.timerId = setTimeout(() => {
          if (quoteRef.ref) {
            const elemSymbol = quoteRef.ref.querySelector(".quote-symbol");
            elemSymbol.classList.remove("symbol-blink");
          }
          quoteRef.timerId = null;
        }, timeout);
      }

      // let timeout = 5 * 1000;
      // const currentTimestamp = (new Date()).getTime();
      // let shouldResetTimer = true;
      // if (quoteRef.timerId && quoteRef.timeout) {
      //   if (quoteRef.timeout - currentTimestamp >= 5 * 1000) {
      //     shouldResetTimer = false;
      //   } else {
      //     timeout += quoteRef.timeout - currentTimestamp;
      //   }
      // }
      // if (shouldResetTimer) {
      //   if (quoteRef.timerId) {
      //     clearTimeout(quoteRef.timerId);
      //   }
      //   quoteRef.timerId = setTimeout(() => {
      //     if (quoteRef.ref) {
      //       const elemSymbol = quoteRef.ref.querySelector(".quote-symbol");
      //       elemSymbol.classList.remove("symbol-blink");
      //     }
      //     quoteRef.timerId = null;
      //     quoteRef.timeout = null;
      //   }, timeout);
      //   quoteRef.timeout = (new Date()).getTime() + timeout;
      // }
    });
  };

  const getPopularData = () => {
    API.getPopular()
      .then((popular) => {
        let symbols = [];
        popular.forEach((arr) => {
          symbols = [...symbols, ...arr];
        });
        // for (let symbol in this.quoteRefs) {
        //   const quoteRef = this.quoteRefs[symbol];
        //   if (quoteRef.ref) {
        //     const elemSymbol = quoteRef.ref.querySelector(".quote-symbol");
        //     elemSymbol.classList.remove("symbol-blink");
        //   }
        // }
        dispatch({
          type: DashboardActionTypes.SET_POPULAR_SYMBOLS,
          value: symbols,
        });
      })
      .catch((error) => {
        console.info(error);
      });
  };

  const renderPopularData = (index) => {
    let res = [];

    let data = (popularSymbols || []).slice(0, 100);
    const len = data.length;
    data.map((item, i) => {
      res.push(
        <div
          key={`popular-data-h3-${index + i}-${item}`}
          className="pr-2"
          style={{
            fontSize: `${Math.floor(32 - 20 * (i / len))}px`,
          }}
          ref={(ref) => {
            if (!quoteRefs.current[item]) {
              quoteRefs.current[item] = {};
            }
            quoteRefs.current[item].ref = ref;
          }}
        >
          <Symbol symbol={item} />
        </div>
      );
    });

    return res;
  };

  return (
    <div
      className={`card popular-section-wrapper ${maximisedView ? "h-auto" : "h-100"} d-flex flex-column p-3`}
      style={{ flex: 1 }}
    >
      <div className="d-flex flex-row justify-content-between mb-1">
        <h4 style={{ marginBottom: "0px" }}>Popular</h4>
      </div>
      <Scrollbars
        autoHide
        style={{
          width: "100%",
          height: "auto",
          flexGrow: 1,
          textTransform: "uppercase",
        }}
        renderTrackVertical={(props) => <div className="track-vertical" {...props} />}
      >
        <div className="d-flex flex-row flex-fill flex-wrap" style={{ alignItems: "baseline" }}>
          {renderPopularData(0)}
        </div>
      </Scrollbars>
    </div>
  );
};

export default Popular;
