import React from "react";
import { Sparklines } from "react-sparklines";
import { SparklinesCurve } from "react-sparklines";
import { SparklinesSpots } from "react-sparklines";
import { CHART_NEGATIVE_COLOR, CHART_POSITIVE_COLOR } from "../constants";
import { isMarketOpen } from "../util";
import API from "../api";
import _ from "lodash";
import styleClasses from "./DiscoveryTable.module.scss";

function Sparkline({ symbol, tf, distValue, sparklineData, widget, updateChartWidget }) {
  const data = (sparklineData[symbol + tf]?.value ?? []).filter((d) => d !== null && d !== undefined);
  const oldData = sparklineData[symbol + tf]?.oldValue;

  const oldDistValue = sparklineData[symbol + tf]?.oldDistValue;

  if (!data || !data.length) {
    return (
      <div className={"d-flex justify-content-start w-100 " + styleClasses.sparklineLoading}>
        <div
          style={{
            width: "70%",
            borderBottom: "1px green solid",
          }}
        ></div>
      </div>
    );
  }
  const dataTime = sparklineData[symbol + tf].time;

  const chartColor = distValue >= 0 ? CHART_POSITIVE_COLOR : CHART_NEGATIVE_COLOR;
  let beaconColor =
    distValue === oldDistValue ? chartColor : distValue > oldDistValue ? CHART_POSITIVE_COLOR : CHART_NEGATIVE_COLOR;

  let blink = false;
  if (oldData && data && !_.isEqual(oldData, data)) {
    if (isMarketOpen()) {
      blink = true;
    }
  }

  const id = "gradient" + symbol + tf;

  return (
    <div className="w-100 h-100 d-flex justify-content-start align-items-center" key={symbol + tf}>
      <div
        className={
          `h-100 d-flex justify-content-center align-items-center cursor-pointer ${styleClasses.sparklineContent} ` +
          (blink ? styleClasses.sparklineSpot : styleClasses.sparklineSpotHide)
        }
        style={{ width: "65px" }}
        onClick={() => {
          updateChartWidget({
            symbol,
            source: widget,
            tf,
          });

          // update for popular
          API.incSymbolStats(symbol);
        }}
      >
        <Sparklines data={data} limit={data.length} height={110} margin={10}>
          <svg>
            <defs>
              <linearGradient id={id} x1="0%" y1="0%" x2="0%" y2="100%">
                <stop offset="20%" stopColor={chartColor} stopOpacity="1" />
                <stop offset="100%" stopColor={chartColor} stopOpacity="0" />
              </linearGradient>
            </defs>
          </svg>
          <SparklinesCurve
            color={chartColor}
            style={{
              strokeWidth: 3,
              stroke: chartColor,
              fill: `url(#${id})`,
              fillOpacity: "0.3",
            }}
          />
          <SparklinesSpots spotColors={{ "-1": beaconColor, 0: beaconColor, 1: beaconColor }} size={8} />
        </Sparklines>
      </div>
    </div>
  );
}

export default Sparkline;
